import React from "react"
import { graphql } from "gatsby"

import { Main } from "../components/Merry/Layout"
import PageWrap from "../components/PageWrap"

const NotFoundPage = () => (
  <PageWrap
    title="404: Not Found"
    description=""
    navOpen={true}
  >
    <Main>
    <h1>Not Found</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
    </Main>
  </PageWrap>
)

export default NotFoundPage
